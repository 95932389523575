body {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  margin: 0;
  padding: 0;
}

.main {
  padding: 1rem;
}

.badge {
  height: 1.2rem;
  padding: 0.3rem;
  opacity: 0.8;
}

.badge:hover {
  opacity: 1;
}